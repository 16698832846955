<template>
  <BgPage>
    <div class="Box">
      <div class="limitBox">
        <div class="Title">
          <p>{{ state.NewsInfo.title }}</p>
          <p>
            <span>发布时间: {{ state.NewsInfo.add_time }}</span>
            <span>来源: {{ state.NewsInfo.source }}</span>
            <!-- <span>浏览次数: {{ state.NewsInfo.views }}次</span> -->
          </p>
        </div>
        <div class="Content">
          <p v-html="state.NewsInfo.html.html" class="article"></p>
          <p v-if="state.NewsInfo.file" class="enclosure">
            <span style="font-weight: bolder">附件:</span>
            <span>
              <span>
                <span class="iconfont" :class="state.iconClass"></span>
                <a target="_blank" :href="state.NewsInfo.file" :download="state.fileName">{{state.fileName}}</a>
              </span>
            </span>
          </p>
        </div>
      </div>
    </div>
    <ShareBox></ShareBox>
  </BgPage>
</template>

<script>
import * as Vue from "vue";
import router from "@/router";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import BgPage from "../../components/BgPage/BgPage.vue";
import api from "@/comment/request/api";
import ShareBox from "@/components/ShareBox/ShareBox";

export default {
  name: "NewsDetails",
  components: { Breadcrumb, BgPage, ShareBox },
  setup(props, { attrs, slots, emit }) {
    const state = Vue.reactive({
      NewsInfo: {html: {}},
      iconClass: 'iconqitawenjian',
      fileName: '',
    });
    let id = router.currentRoute._rawValue.query.id;
    api.GetNewsDetails(id).success((res) => {
      state.NewsInfo = res.data.data;
      // 文件
      if (res.data.data.file) {
        const fileArr = res.data.data.file.split('.');
        const fileType = fileArr[fileArr.length - 1];
        let iconClass='iconqitawenjian';
        if(fileType==='doc' || fileType==='docx'){
          iconClass='iconwordwenjian'
        }else if(fileType==='ppt' || fileType==='pptx'){
          iconClass='iconpptwenjian'
        }else if(fileType==='excel' || fileType==='xls' || fileType==='xlsx'){
          iconClass='iconexcelwenjian'
        }else if(fileType==='pdf'){
          iconClass='iconpdfwenjian'
        }
        state.iconClass = iconClass;
        const fileArr1 = res.data.data.file.split('/');
        state.fileName = fileArr1[fileArr1.length - 1];
      }
    });
    return {
      state,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "/src/comment/comment";
.limitBox {
  .Title {
    padding-top: 40px;
    text-align: center;
    padding-bottom: 20px;

    p:nth-child(1) {
      font-size: 36px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      //color: #7F0404;
      color: black;
      padding-bottom: 20px;
    }

    p:nth-child(2) {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;

      span {
        margin: 0 20px;
      }
    }
  }

  .Content {
    background-color: white;
    padding: 20px;
    p {
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
    }
    .article {
      /deep/p {
        display: block;
        margin-bottom: 25px;
      }
      /deep/span {
        display: block;
        margin-bottom: 25px;
      }
    }
    img {
      max-width: 100% !important;
      max-height: 100% !important;
      vertical-align: bottom;
    }
    /deep/img {
      max-width: 100% !important;
      max-height: 100% !important;
      vertical-align: bottom;
    }
    /deep/.video-wrap {
      display: flex;
      justify-content: center;
    }
    /deep/video {
      max-width: 100%;
      max-height: 800px;
    }
  }
}
@media screen and (max-width: 1300px) {
  .Content {
    width: 900px;
    min-width: 900px;
    margin: 0 auto;
  }
}
.enclosure {
  margin-top: 20px;
  display: flex;
  span {
    display: block;
    margin-bottom: 10px;
    a {
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgb(0, 102, 204);
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  > span:nth-child(2) {
    margin-left: 10px;
    > span {
      display: flex;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgb(0, 102, 204);
    }
  }
}
</style>